<template>
  <div id="lab-logo">
    <canvas id="paper-canvas" class="canvas-style" height="200px" width="300px"></canvas>
  </div>
</template>

<script>

const paper = require('paper');

export default {
  name: 'LabLogo',
  props: ['scale'],
  data() {
    return {
      logoGrid :[[1, 1, 1, 1, 1], [0, 0, 0, 0, 1], [0, 1, 1, 1, 1], [1, 0, 0, 1, 0], [0, 1, 1, 1, 1], [1, 1, 1, 1, 1], [1, 0, 1, 0, 1], [0, 1, 0, 1, 0]],
      gutter: 16,
      timeElapsed: 0,
      dots: [],
      paramLogo: { 
        minScale : { 
          min: 0.2, max : 1 
        }, 
        maxScale : { 
          min: 1, 
          max: 2.2 
        }, 
        speed: { 
          min: -0.005, 
          max: 0.005 
        }, 
        freeGrowthDuration: 10000, 
        initialScaleHold: 2000 
      },
      animationDelayAfterStart: 10,
      backgroundOpacity: 5,
      gridParam: { 
        gridHeight: 5, 
        circleSize: 15, 
        offset: {
          initial: 2, 
          everyOther: 3
        }
      },
      backToSize: false
    }
  },
  components: {

  },
  methods: {
    randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    setupLogo() {
      this.generateDots(true);
      for (var i = 0; i < this.dots.length; i++) {
        var circle = new paper.Path.Circle({x: this.dots[i].position.x, y: this.dots[i].position.y}, this.dots[i].currentScale *  this.dots[i].radius);
        circle.fillColor = 'white';
        circle.applyMatrix = false;
        circle.data.speed = this.dots[i].speed;
        circle.data.minScale = this.dots[i].minScale;
        circle.data.maxScale = this.dots[i].maxScale;
        
        let self = this;

        circle.onFrame = function () {

          this.scale(1 + this.data.speed);
          if ((this.scaling.x <= this.data.minScale) || (this.scaling.x >= this.data.maxScale) && !self.backToSize) {
            this.data.speed = - this.data.speed;
          }
        }
      }
      paper.view.translate(paper.view.center.x, paper.view.center.y);
    },
    generateDots(useOffset) {
      var x = - 4 * this.gridParam.circleSize - 3 * this.gutter;
      var y = - 2.5 * this.gridParam.circleSize - 2 * this.gutter;
      var offset;
      for (var i = 0; i < this.logoGrid.length; i++) {
        for (var j = 0; j < this.gridParam.gridHeight; j++) {
          offset = 0;
          if (this.logoGrid[i][j] != 0) {
            if (useOffset) {
              if ((i >= this.gridParam.offset.initial) && (i < this.gridParam.offset.initial + this.gridParam.offset.everyOther)) {
                offset += this.gridParam.circleSize / 2;
              } else if ( i > this.gridParam.offset.everyOther + 1) {
                offset += this.gridParam.circleSize;
              }
            }
            this.dots.push( {
              position: { 
                x: x + i * (this.gridParam.circleSize + this. gutter) + offset, 
                y: y + j * (this.gridParam.circleSize + this.gutter)
              }, 
              minScale: this.randomNumber(this.paramLogo.minScale.min, this.paramLogo.minScale.max),
              maxScale: this.randomNumber(this.paramLogo.maxScale.min, this.paramLogo.maxScale.max),
              currentScale: 1,
              radius: this.gridParam.circleSize,
              param: this.paramLogo,
              speed: this.randomNumber(this.paramLogo.speed.min, this.paramLogo.speed.max),
              initialScaleHold :  this.paramLogo.initialScaleHold,
              onPause : false,
              currentTime : 0,
              freeAnimationDuration: this.paramLogo.freeGrowthDuration,
              backToSize : false,
              safeSpeed: this.speed
            });
          }
        }
      }
    }
  },
  mounted() {
    paper.setup(document.getElementById("paper-canvas"));
    this.setupLogo();
  }
}
</script>

<style scoped>
  .canvas-style {
    margin-left: -1.5em;
  }

</style>
