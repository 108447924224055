<template>
  <div id="banner">
    <div id="bg-container">
      <iframe src="https://player.vimeo.com/video/485739568?autoplay=1&loop=1&muted=1" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen id="video-banner"></iframe>
     <!--  <video autoplay muted loop id="video-banner">
        <source src="../assets/videos/tangible-banner.mp4" type="video/mp4">
      </video> -->
    </div>
    <div class="welcome-top">
      <p> 00/ welcome to the </p>
      <LabLogo :scale="1"></LabLogo>  
    </div> 

    
  </div>
</template>

<script>

import LabLogo from '@/components/LabLogo'

export default {
  name: 'HeaderBanner',
  data() {
    return {
      doScroll: true,
      currentMargin: 0,
      t: null
    }
  },
  components: {
    LabLogo
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(e) {
      if (this.doScroll){
        if (window.scrollY > 100) {
          e.preventDefault();
          this.doScroll = false;
          var scrollOptions = {
            left: 0,
            top: window.innerHeight,
            behavior: 'smooth'
          }
          window.scrollTo(scrollOptions);
          // setInterval(() => {
          //   if (this.currentMargin * 5 >= 300) {
          //     clearInterval(this);
          //   } else {
          //     this.currentMargin ++;
          //     document.getElementById("menu-column").style.marginTop = this.currentMargin * 5 + "px";
          //   }
          // }, 5);
        }
      }

      if (!this.doScroll && window.scrollY < 15) {
        this.doScroll = true;
      }

    }
  }

}
</script>

<style scoped>
  #video-banner {
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw;
    position: relative;   
  }

  .welcome-top {
    position: fixed;
    top: 0;
    color: white;
    padding: 20px 2.5em;
    width: 100%;
    margin: auto;
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  #bg-container {
    overflow: hidden;
    position: relative;
  }

</style>
