<template>
	<div id="app">
		<div class="container">
			<OverlayGrid></OverlayGrid>
		</div>
		<HeaderBanner></HeaderBanner>
		<div class="columns container">
			<MenuColumn @sectionSelected="updateSection"></MenuColumn>
			<ContentColumn :name="currentSection"></ContentColumn>
		</div>
	</div>
	
</template>

<script>

	import OverlayGrid from '@/components/OverlayGrid'
	import HeaderBanner from '@/components/HeaderBanner'
	import MenuColumn from '@/components/MenuColumn'
	import ContentColumn from '@/components/ContentColumn'

	export default {
		name: 'App',
		components: {
			MenuColumn,
			ContentColumn,
			HeaderBanner,
			OverlayGrid
		},
		data() {
			return {
				currentSection: 'home'
			}
		},
		methods : {
			updateSection(name) {
				this.currentSection = name;
			}
		}
	}
</script>

<style>

	body {
		background-color: black;
		margin: 0;
		color: white;
	}

	p, h4 {
		margin: 0;
		padding: 0;
		font-size: 25px;
		font-weight: normal;
	}

	p, a, button, h1, h2, h3, h4, h5 {
		z-index: 2;
	}

	#app {
		font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.container {
		max-width: 1920px;
		margin: auto;
	}

	:root {
		--key-color: #FFC700;
	}

	.underlined {
		text-decoration: underline;
	}

	button:focus {
		outline:0;
	}

	.key-color, button.text-like.key-color, a.text-like.key-color {
		color: var(--key-color);
	}

	button.text-like.key-color:hover, a.text-like.key-color:hover {
		color: var(--key-color);
		opacity: 80%;
	}

	p.short {
		width: 50%;
	}

	p.bold {
		font-weight: 600;
	}

	button.text-like, a.text-like {
		color: white;
		font-size: 25px;
		background-color: transparent;
		text-align: left;
		border: none;
		padding: 0;
		cursor: pointer;
	}

	button.text-like:hover {
		color: #D8D8D8;
	}

	button.text-like.deactivated {
		color: #9C9C9C;
	}

	.mt25 {
		margin-top: 2.5rem;
	}

	.mt12 {
		margin-top: 1.25rem;
	}

	.mb-100 {
		margin-bottom: 5rem;
	}

	.columns {
		display: flex;
		flex-direction: row;
	}

	p.subtext {
		font-size: 18px;
		padding: 0.3em 3em;
	}

	#content-column::-webkit-scrollbar, #menu-column::-webkit-scrollbar  {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	#content-column, #menu-column  {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}

	a.button {
		border: 1px white solid;
		color: white;
		text-decoration: none;
		font-size: 25px;
		padding: 0.2em 0.4em;
		line-height: 1;
		width: fit-content;
	}

	a.button:hover {
		opacity: 0.8;
	}
</style>
