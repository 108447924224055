<template>
  <div id="grid">
    <ul>
      <li v-for="n in 19" class="grid-line" :key="n">
      </li>
    </ul>
   
  </div>
</template>

<script>
export default {
  name: 'OverlayGrid',
  data() {
    return {
      
    }
  }

}
</script>

<style scoped>

  #grid {
    position: fixed;
    width: calc(100% - 5rem);
    height: 100vh;
    margin: 0 2.5rem;
    z-index: 1;
    pointer-events: none;
  }

  #grid ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type:none;
  }

  .grid-line {
    width: 5%;
    border-left: rgba(255, 255, 255, 0.1) solid 1px;
  }

  .grid-line:last-child {
    width: 0%;
  }

</style>
