<template>
  <div id="menu-column">
    <p class="mt25 short">come with your covid free bubble and experience the latest in interactive art and technology</p>
    <div class="menu-block mt25">
      <button class="text-like" @click="install" v-bind:class="{deactivated: links.install}">01/ installations on display</button>
      <button class="text-like" @click="covid" v-bind:class="{deactivated: links.covid}">02/ covid-19</button>
      <button class="text-like" @click="how" v-bind:class="{deactivated: links.how}">03/ how it works</button>
      <button class="text-like" @click="contact" v-bind:class="{deactivated: links.contact}">04/ contact</button>
      <button class="text-like" @click="about" v-bind:class="{deactivated: links.about}">05/ about</button>
    </div>
    <p class="key-color short bold mt25">/stay safe by bringing your bubble of friends and family</p>
    <a class="button mt12" href="https://app.acuityscheduling.com/schedule.php?owner=21055500">book now</a>
  </div>
</template>

<script>

export default {
  name: 'MenuColumn',
  components: {
  },
  data() {
    return {
      currentLink: '',
      links: {
        install: false,
        covid: false,
        how: false,
        contact: false,
        about: false
      }
    }
  },
  methods: {
    install() {
      this.currentLink = 'install';
      this.$emit('sectionSelected', this.currentLink);
      this.setAllLinksToFalseExpect('install');
    },
    covid() {
      this.currentLink = 'covid';
      this.$emit('sectionSelected', this.currentLink);
      this.setAllLinksToFalseExpect('covid');
    },
    how() {
      this.currentLink = 'how';
      this.$emit('sectionSelected', this.currentLink);
      this.setAllLinksToFalseExpect('how');
    },
    contact() {
      this.currentLink = 'contact';
      this.$emit('sectionSelected', this.currentLink);
      this.setAllLinksToFalseExpect('contact');
    },
    about() {
      this.currentLink = 'about';
      this.$emit('sectionSelected', this.currentLink);
      this.setAllLinksToFalseExpect('about');
    },
    setAllLinksToFalseExpect(name) {
      Object.keys(this.links).forEach(key => {
        if (key == name) {
          this.links[key] = false;
        } else {
          this.links[key] = true;
        }
      });
    }

  }

}
</script>

<style scoped>
  #menu-column {
    display: flex;
    flex-direction: column;
    width: 47.4%;
    padding-left: 2.6rem;
    height: calc(100vh - 220px);
    margin-top: 220px;
    overflow: scroll;
  }

  .menu-block {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 50%;
    height: auto;
  }

</style>
