<template>
  <div id="content-column">
    <div v-for="blocks in content[name]" :key="blocks.id" class="media ">
      <video autoplay muted loop class="video-block" v-if="blocks.type == 'video'">
        <source v-bind:src="pathVideo(blocks.url)" type="video/mp4">
      </video>
      <img v-if="blocks.type == 'img'" v-bind:src="pathImg(blocks.url)">
      <div v-if="blocks.type == 'text'" class="text-block mb-100">
        <h4> {{ blocks.title }} </h4>
        <p> {{ blocks.content }} </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentColumn',
  props: ['name'],
  watch: {
    name() {
      this.resetScroll();
    }
  },
  data() {
    return {
      currentSection: 'home',
      content: {
        home : [ ],
        install : [
          { url: "lab-visitor",
            offset: '',
            type: 'video',
            id: 'i0'
          },
          { title: '/sonic x 3',
            content: "ever wanted to be a DJ? dream no more! compose music either solo or together using our playfyul sonic mucis tables. each table has 4 interactive pads controlling drumbs, base keys and effects. this installation allows for 1 to 12 people to play together",
            type: 'text',
            id: 'i1'
          },
          { url: "lab-graffiti",
            offset: '',
            type: 'video',
            id: 'i2'
          },
          { url: "lab-sonic",
            offset: '',
            type: 'video',
            id: 'i3'
          },
          { url: "lab-halo-swarms",
            offset: '',
            type: 'video',
            id: 'i4'
          },
          { url: "lab-screen",
            offset: '',
            type: 'video',
            id: 'i5'
          },
          { url: "lab-kinect-interactive",
            offset: '',
            type: 'video',
            id: 'i6'
          },{ url: "lab-neons",
            offset: '',
            type: 'video',
            id: 'i7'
          }
        ],
        covid : [
          { url: "lab-mask",
            offset: '',
            type: 'img',
            id: 'c0'
          },
          { title: '/how we handle things',
            content: "We book groups for 2h with 1h for cleanup and designfecting\nCome with your bubble\nEvery user needs to wear a mask and gloves, and we provide them for you\nWe have minimized the number of surfaces that need to be touched\nHand sanitazing station are located right by the door",
            type: 'text',
            id: 'c1'
          },
          { url: "lab-sanitizer",
            offset: '',
            type: 'img',
            id: 'c2'
          },
          { url: "lab-gloves",
            offset: '',
            type: 'img',
            id: 'c3'
          }
        ],
        contact : [ 
          { url: "lab-contact-ironworks",
            text: '',
            offset: '',
            type: 'img',
            id: 'ct0'
          },
          { url: "lab-contact-plan",
            text: '',
            offset: '',
            type: 'img',
            id: 'ct1'
          },
          { url: "lab-contact-east-van",
            text: '',
            offset: '',
            type: 'img',
            id: 'ct2'
          } ]
      }
    }
  },
  methods: {
    pathVideo(name) {
      return require('../assets/videos/' + name + '.mp4');
    },
    pathImg(name) {
      return require('../assets/img/' + name + '.png');
    },
    resetScroll() {
      document.getElementById("content-column").scroll(0,0);
    }
  }
}
</script>

<style scoped>
  #content-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    overflow: scroll;
  }

  #content-column video, #content-column img {
    width: 100%;
    height: 50vw;
  }


  #content-column .media {
    width: 100%;
    height: 50vw;
  } 

  .text-block p {
    font-size: 18px;
  }

  .text-block {
    padding-top: 0.2em;
    padding-right: 0.2em;
  }

</style>
